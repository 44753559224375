// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import { toast } from "utils/Toast";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import TeamInfo from "types/models/TeamInfo";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import components.
import { Link, Typography } from "@mui/material";
import Button from "components/common/button/Button";
import FieldWrapper from "components/common/form/FieldWrapper";
import CustomDialog from "../CustomDialog";

// Import utilities.
import CloneUtils from "utils/Clone";

// Import servcies.
import BrainCloudPropertiesService from "services/BrainCloudProperties";
import Services from "./services";

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
    logout: () => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    acceptableUsePolicyUrl: string | null;
    privacyPolicyUrl: string | null;
    termsOfServiceUrl: string | null;

    teamInfo: TeamInfo | null;

    accepted: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
        logout: () => dispatch({ type: "authentication.logout" }),
    };
};

class TermsOfService extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        acceptableUsePolicyUrl: null,
        privacyPolicyUrl: null,
        termsOfServiceUrl: null,

        teamInfo: null,

        accepted: false,
    };

    componentDidMount() {
        if (this.props.session.requiresTermsOfServiceAcceptance) {
            this.loadTermsOfService();
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.session.companyId !== this.props.session.companyId && this.props.session.requiresTermsOfServiceAcceptance) {
            this.loadTermsOfService();
        }
    }

    loadTermsOfService = async () => {
        try {
            const promiseBatch = await Promise.all([
                BrainCloudPropertiesService.getSystemProperty("acceptableUseUrl"),
                BrainCloudPropertiesService.getSystemProperty("privacyUrl"),
                BrainCloudPropertiesService.getSystemProperty("tosUrl"),
                Services.getTeamInfo(),
            ]);

            this.setState({
                acceptableUsePolicyUrl: promiseBatch[0] as string,
                privacyPolicyUrl: promiseBatch[1] as string,
                termsOfServiceUrl: promiseBatch[2] as string,

                teamInfo: promiseBatch[3],

                accepted: false,
            });
        } catch (error: any) {
            toast.error(error);
        }
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "accepted":
                this.setState({ accepted: value });

                break;
            default:
            // Do nothing.
        }
    };

    onContinue = async () => {
        const { session } = this.props;

        try {
            await Services.acceptTermsOfService();

            const updatedSession = CloneUtils.clone(session) as Session;

            updatedSession.requiresTermsOfServiceAcceptance = false;

            // Update the session in the redux store.
            this.props.setSession(updatedSession);
            this.props.saveSession();
        } catch (error: any) {
            toast.error(error);
        }
    };

    isFormValid = () => {
        const { accepted } = this.state;

        return accepted;
    };

    render() {
        const { classes, session } = this.props;
        const { acceptableUsePolicyUrl, privacyPolicyUrl, termsOfServiceUrl, teamInfo, accepted } = this.state;

        if (!session.requiresTermsOfServiceAcceptance) return null;

        const isNewTeam = teamInfo && teamInfo.tosAcceptances && teamInfo.tosAcceptances.length === 0;
        return (
            <CustomDialog
                id="terms-of-service"
                className={classes.root}
                open={true}
                ready={teamInfo != null}
                hideFullscreen={true}
                hideDismiss={true}
                header={
                    <strong>
                        <Trans>Terms Of Service</Trans>
                    </strong>
                }
                content={
                    <>
                        <div className={classnames({ [classes.content]: true, [classes.mobile]: isMobile })}>
                            <span className={classes.text}>
                                {!isNewTeam && (
                                    <Typography>
                                        <Trans>One or more of our policy documents has been updated.</Trans>
                                    </Typography>
                                )}

                                <Typography>
                                    <Trans>A team administrator must review and accept the documents before proceeding:</Trans>
                                </Typography>
                            </span>
                        </div>

                        <div className={classes.checkbox}>
                            <FieldWrapper className={classes.field} type={"checkbox"} name={"accepted"} value={accepted} onChange={this.handleChange} disabled={!session.isTeamAdmin} />
                            <>
                                <Trans>I Accept the</Trans>
                                {acceptableUsePolicyUrl && (
                                    <Typography>
                                        <Link data-id={"acceptable-use-policy"} href={acceptableUsePolicyUrl} target="_blank" style={{ marginLeft: "0.25em" }}>
                                            <Trans>Acceptable Use Policy</Trans>
                                        </Link>
                                        {","}
                                    </Typography>
                                )}
                                {privacyPolicyUrl && (
                                    <Link data-id={"privacy-policy"} href={privacyPolicyUrl} target="_blank" style={{ marginLeft: "0.25em" }}>
                                        <Trans>Privacy Policy</Trans>
                                    </Link>
                                )}
                                {termsOfServiceUrl && (
                                    <Typography style={{ marginLeft: "0.25em" }}>
                                        <Trans>and</Trans>
                                        <Link data-id={"terms-of-service"} href={termsOfServiceUrl} target="_blank" style={{ marginLeft: "0.25em" }}>
                                            <Trans>Terms of Service</Trans>
                                        </Link>
                                    </Typography>
                                )}
                                {"."}
                            </>
                        </div>
                    </>
                }
                actions={
                    <>
                        <Button id={"cancel"} type={"secondary"} onClick={this.props.logout}>
                            <Trans>Logout</Trans>
                        </Button>
                        <Button id={"confirm"} type={"primary"} onClick={this.onContinue} disabled={!this.isFormValid()}>
                            <Trans>Continue</Trans>
                        </Button>
                    </>
                }
            />
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            "& > .MuiDialog-container > .MuiDialog-paper": {
                flex: "0 1 auto",
                minWidth: "unset",
                padding: "1em",
            },
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            margin: "1em",
        },
        mobile: {
            flexDirection: "column",
        },
        text: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
        },
        field: {
            margin: "0",
            padding: "0",
            overflow: "hidden",
            "& > #control": {
                flex: "0 0 auto",
                marginLeft: "auto",
            },

            "& > #label": {
                flex: "1 1 auto",
                overflow: "hidden",
                whiteSpace: "break-spaces",
            },
        },
        checkbox: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TermsOfService));
