// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { toast } from "utils/Toast";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Trans } from "@lingui/macro";
import classnames from "classnames";
import md5 from "md5";

// Import types.
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import AccountStatus from "types/enums/AccountStatus";
import ServiceStatus from "types/enums/ServiceStatus";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import PortalPrivilege, { hasReadAccess } from "types/common/PortalPrivilege";

// Import components.
import { Divider, Typography } from "@mui/material";
import Tabs, { TabConfig } from "components/common/tabs";
import IconButton from "components/common/button/IconButton";
import PlayerMenuItem from "./PlayerMenuItem";
import AppMenuItem from "./AppMenuItem";
import TeamMenuItem from "./TeamMenuItem";
import AppSelector from "./AppSelector";
import NavigatorRouteTree from "./NavigatorRouteTree";
import FixBillingLink from "components/common/widgets/FixBillingLink";
import LabelWithCopy from "components/common/LabelWithCopy";

// Import icons.
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import SuperIcon from "@mui/icons-material/Bolt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

// Import utilities.
import UnitUtils from "utils/Unit";

//Import Service
import Services from "../BreadcrumbBarAndBanner/services";

interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    themeConfiguration: ThemeConfiguration;
    session: Session;
    currentUser: User | null;
    currentCompanyAlias: TeamInfo | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    availablePrivileges: PortalPrivilege[];
}
interface DISPATCH_PROPS {
    setTheme: (companyId: string | null, fallbackCompanyId?: string | null) => void;
    setAppId: (appId: string, path?: string, history?: History) => void;
    setPlayerId: (playerId: string | null, path?: string, history?: History) => void;
    refreshPortalDisabledState: () => void;
}
interface OWN_PROPS {
    open: boolean;
    pinned: boolean;

    definitions: PortalRouteDefinition[];

    onToggleOpen: (open?: boolean) => void;
    onTogglePinned: (pinned?: boolean) => void;

    onToggleProfileOpen: (open?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

interface STATE {
    context: "super" | "team" | "app" | "user" | null;

    appSelectorOpen: boolean;

    lastSuperPath: string;
    lastTeamPath: string;
    lastAppPath: string;
    lastUserPath: string;

    refreshingTheme: boolean;

    activeTabIndex: number;

    teamInfo: TeamInfo | null;
    isLoading: boolean;
}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        applicationInformation: state.applicationInformation,
        themeConfiguration: state.themeConfiguration,
        session: state.session,
        currentUser: state.currentUser,
        currentCompanyAlias: state.currentCompanyAlias,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        availablePrivileges: state.availablePrivileges,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setTheme: (companyId: string | null, fallbackCompanyId?: string | null) => dispatch({ type: "theme.setTheme", payload: { companyId: companyId, fallbackCompanyId: fallbackCompanyId } }),
        setAppId: (appId: string, path?: string, history?: History) => dispatch({ type: "app.setAppId", payload: { appId, path, history } }),
        setPlayerId: (playerId: string | null, path?: string, history?: History) => dispatch({ type: "app.setPlayerId", payload: { playerId, path, history } }),
        refreshPortalDisabledState: () => dispatch({ type: "initialization.refreshPortalDisabledState" }),
    };
};

const APP_ITEM_COUNT = 5;

class Navigation extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        context: null,

        appSelectorOpen: false,

        lastSuperPath: "/super/dashboard",
        lastTeamPath: "/team/dashboard",
        lastAppPath: "/app/dashboard",
        lastUserPath: "/user/user-browser",

        refreshingTheme: false,

        activeTabIndex: 0,

        teamInfo: null,
        isLoading: false,
    };

    componentDidMount() {
        this.getTeamInfo();
        this.refreshStateBasedOnLocation();
    }

    componentDidUpdate(prevProps: PROPS, prevState: STATE) {
        if (this.props.applicationInformation.loadingLoginState || this.props.applicationInformation.loadingBasicState) return;

        if (prevProps.location.pathname !== this.props.location.pathname || (prevProps.applicationInformation.loadingBasicState && !this.props.applicationInformation.loadingBasicState)) {
            this.refreshStateBasedOnLocation();
        }

        if (prevState.refreshingTheme === this.state.refreshingTheme && !this.state.refreshingTheme) {
            this.setState({ refreshingTheme: true }, () => {
                if (this.requiresThemeChange()) {
                    this.refreshThemeBasedOnLocation();
                }

                this.setState({ refreshingTheme: false });
            });
        }
        this.getTeamInfo();
    }
    getTeamInfo = async () => {
        const { currentCompanyAlias, availableCompanies, session, currentUser } = this.props;
        const { isLoading } = this.state;

        const targetCompany = session.isSuper ? currentCompanyAlias : availableCompanies.find((item) => item.companyId === session.companyId) || null;

        if (!isLoading && targetCompany?.companyId && targetCompany?.companyId !== this.state.teamInfo?.companyId) {
            this.setState({ isLoading: true });
            try {
                const teamInfo = await Services.getTeam(targetCompany.companyId);
                const targetTeamMember = teamInfo && teamInfo.teamAccess ? teamInfo.teamAccess.find((item) => item.profileId === currentUser?.profileId) : null;

                if (teamInfo.accountStatus === AccountStatus.POOR_STANDING || teamInfo.serviceStatus === ServiceStatus.SUSPENDED) {
                    toast.warning(
                        <>
                            <span style={{ display: "flex", alignItems: "center", width: "16em" }}>
                                <Trans>Team Name: </Trans>
                                <LabelWithCopy id={"companyName"} copyOnHover={true} labelStyle={{ color: "var(--primary-color, inherit)", fontWeight: "bold" }} value={teamInfo ? teamInfo.companyName : null}>
                                    {teamInfo.companyName}
                                </LabelWithCopy>
                            </span>
                            <Typography>
                                <Trans>ALERT: There is a billing problem and the system has automatically suspended your app(s). Your customers will experience reduced functionality while the team is suspended. To solve this problem Fix Now</Trans>
                                {((session.isSuper && targetTeamMember) || !session.isSuper) && <FixBillingLink teamInfo={teamInfo} />}
                            </Typography>
                        </>
                    );
                }
                this.setState({ teamInfo });
            } catch (error: any) {
                toast.error(error);
            } finally {
                this.setState({ isLoading: false });
            }
        }
    };
    requiresThemeChange() {
        const { location, themeConfiguration, session } = this.props;

        const context = location.pathname.startsWith("/super") ? "super" : location.pathname.startsWith("/team") ? "team" : location.pathname.startsWith("/app") ? "app" : location.pathname.startsWith("/user") ? "user" : null;

        const activeTheme = themeConfiguration.activeTheme;

        if (!activeTheme) return true;

        if (activeTheme.companyId?.endsWith("-PREVIEW")) return false;

        const targetTheme =
            themeConfiguration.availableThemes.find((item) => {
                if (session.isSuper) {
                    switch (context) {
                        case "super":
                            return item.enabled && item.companyId === session.companyId;
                        case "team":
                            return item.enabled && item.companyId === session.companyIdAlias;
                        case "app":
                            return item.enabled && item.companyId === session.companyIdAlias;
                        case "user":
                            return item.enabled && item.companyId === session.companyIdAlias;
                        default:
                            return false;
                    }
                } else {
                    switch (context) {
                        case "team":
                            return item.enabled && item.companyId === session.companyId;
                        case "app":
                            return item.enabled && item.companyId === session.companyId;
                        case "user":
                            return item.enabled && item.companyId === session.companyId;
                        default:
                            return false;
                    }
                }
            }) || null;

        if (targetTheme) {
            if (targetTheme.companyId !== activeTheme.companyId) {
                return true;
            }
        } else {
            if (activeTheme.companyId != null) {
                return true;
            }
        }

        return false;
    }

    refreshThemeBasedOnLocation() {
        const { location, session } = this.props;

        const context = location.pathname.startsWith("/super") ? "super" : location.pathname.startsWith("/team") ? "team" : location.pathname.startsWith("/app") ? "app" : location.pathname.startsWith("/user") ? "user" : null;

        switch (context) {
            case "super":
                if (session.companyId) {
                    this.props.setTheme(session.companyId);
                }

                break;
            case "team":
                if (session.isSuper && session.companyIdAlias) {
                    this.props.setTheme(session.companyIdAlias);
                } else {
                    if (session.companyId) {
                        this.props.setTheme(session.companyId);
                    }
                }

                break;
            case "app":
                if (session.isSuper && session.companyIdAlias) {
                    this.props.setTheme(session.companyIdAlias);
                } else {
                    if (session.companyId) {
                        this.props.setTheme(session.companyId);
                    }
                }

                break;
            case "user":
                if (session.isSuper && session.companyIdAlias) {
                    this.props.setTheme(session.companyIdAlias);
                } else {
                    if (session.companyId) {
                        this.props.setTheme(session.companyId);
                    }
                }

                break;
            default:
                return false;
        }
    }

    refreshStateBasedOnLocation() {
        const { location, session } = this.props;
        const { lastSuperPath, lastTeamPath, lastAppPath, lastUserPath, activeTabIndex } = this.state;

        const context = location.pathname.startsWith("/super") ? "super" : location.pathname.startsWith("/team") ? "team" : location.pathname.startsWith("/app") ? "app" : location.pathname.startsWith("/user") ? "user" : null;

        const updatedLastSuperPath = context === "super" ? location.pathname : lastSuperPath;
        const updatedLastTeamPath = context === "team" ? location.pathname : lastTeamPath;
        const updatedLastAppPath = context === "app" ? location.pathname : lastAppPath;
        const updatedLastUserPath = context === "user" ? location.pathname : lastUserPath;

        this.setState(
            {
                context: context,
                lastSuperPath: updatedLastSuperPath,
                lastTeamPath: updatedLastTeamPath,
                lastAppPath: updatedLastAppPath,
                lastUserPath: updatedLastUserPath,
                activeTabIndex: session.isSuper
                    ? context === "super"
                        ? 0
                        : context === "team"
                        ? 1
                        : context === "app"
                        ? 2
                        : context === "user"
                        ? 3
                        : activeTabIndex
                    : context === "team"
                    ? 0
                    : context === "app"
                    ? 1
                    : context === "user"
                    ? 2
                    : activeTabIndex,
            },
            () => {
                this.props.refreshPortalDisabledState();
            }
        );
    }

    onContextChange = (context: "super" | "team" | "app" | "user") => {
        const { history, location, session } = this.props;
        const { lastSuperPath, lastTeamPath, lastAppPath, lastUserPath } = this.state;

        switch (context) {
            case "super":
                if (lastSuperPath !== location.pathname && session.isSuper && session.companyId) {
                    history.push(lastSuperPath);
                }

                break;
            case "team":
                if (lastTeamPath !== location.pathname && ((session.isSuper && session.companyIdAlias) || (!session.isSuper && session.companyId))) {
                    history.push(lastTeamPath);
                }

                break;
            case "app":
                if (lastAppPath !== location.pathname && session.appId) {
                    history.push(lastAppPath);
                }

                break;
            case "user":
                if (lastUserPath !== location.pathname && session.appId) {
                    history.push(lastUserPath);
                }

                break;
            default:
            // Do nothing.
        }
    };

    onToggleAppSelector = () => {
        this.setState({ appSelectorOpen: !this.state.appSelectorOpen });
    };

    onAppChange = (appId: string) => {
        const { history, session } = this.props;
        const { lastAppPath } = this.state;

        if (session.appId !== appId) {
            this.props.setAppId(appId, lastAppPath, history);
        } else {
            this.onContextChange("app");
        }
    };

    onNavigation = (_pathChanged: boolean) => {
        this.setState({ appSelectorOpen: false }, () => {
            if (this.props.open && !this.props.pinned) {
                this.props.onToggleOpen();
            }
        });
    };

    setActiveTab = (index: number) => {
        this.setState({ activeTabIndex: index }, () => {
            const { session } = this.props;

            if (session.isSuper) {
                switch (index) {
                    case 0:
                        this.onContextChange("super");
                        break;
                    case 1:
                        this.onContextChange("team");
                        break;
                    case 2:
                        this.onContextChange("app");
                        break;
                    case 3:
                        this.onContextChange("user");
                        break;
                    default:
                    // Do nothing.
                }
            } else {
                switch (index) {
                    case 0:
                        this.onContextChange("team");
                        break;
                    case 1:
                        this.onContextChange("app");
                        break;
                    case 2:
                        this.onContextChange("user");
                        break;
                    default:
                    // Do nothing.
                }
            }
        });
    };
    prepareTabConfigs = (rootFontSizeInPX: number): TabConfig[] => {
        const { classes, session, availableCompanies, currentCompanyAlias, availableApps, availablePrivileges } = this.props;
        const { appSelectorOpen, teamInfo } = this.state;

        const tabConfigs: TabConfig[] = [];

        const targetCompany = availableCompanies.find((item) => item.companyId === session.companyId) || null;
        const targetCompanyAlias = session.isSuper ? currentCompanyAlias : targetCompany;
        const targetApp = availableApps.find((item) => item.appId === session.appId) || null;
        const targetPlayerSummary = session.playerId && session.playerSummary ? session.playerSummary : null;

        if (session.isSuper) {
            tabConfigs.push({
                id: "super",
                label: <SuperIcon />,
                component: (
                    <span className={classes.tabPanel}>
                        <div style={{ width: "100%" }}>
                            {!targetCompany && (
                                <span style={{ width: "100%", padding: "0.3125em", overflow: "hidden" }}>
                                    <Typography style={{ flex: "0 0 auto", fontStyle: "italic", marginLeft: "auto", marginRight: "auto", paddingLeft: "0.3125em", paddingRight: "0.3125em" }} noWrap>
                                        <Trans>No Team Selected</Trans>
                                    </Typography>
                                </span>
                            )}

                            {targetCompany && (
                                <span style={{ width: "100%", overflow: "hidden" }}>
                                    <TeamMenuItem style={{ flex: "1 1 auto", minHeight: rootFontSizeInPX * 2.5, maxHeight: rootFontSizeInPX * 2.5 }} teamInfo={targetCompany} />
                                </span>
                            )}
                        </div>
                    </span>
                ),
            });
        }

        tabConfigs.push({
            id: "team",
            label: (
                <span style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                        <Trans>Team</Trans>
                    </Typography>
                    {teamInfo && (teamInfo.accountStatus === AccountStatus.POOR_STANDING || teamInfo.serviceStatus === ServiceStatus.SUSPENDED) && (
                        <PriorityHighIcon style={{ color: "var(--label-negative-color, inherit)", alignSelf: "center", marginRight: "0.25em", height: "0.85em", width: "0.85em" }} />
                    )}
                </span>
            ),
            component: (
                <span className={classes.tabPanel}>
                    <div style={{ width: "100%" }}>
                        {!targetCompanyAlias && (
                            <span style={{ width: "100%", padding: "0.3125em", overflow: "hidden" }}>
                                <Typography style={{ flex: "0 0 auto", fontStyle: "italic", marginLeft: "auto", marginRight: "auto", paddingLeft: "0.3125em", paddingRight: "0.3125em" }} noWrap>
                                    <Trans>No Team Selected</Trans>
                                </Typography>
                            </span>
                        )}

                        {targetCompanyAlias && (
                            <span style={{ width: "100%", overflow: "hidden" }}>
                                <TeamMenuItem style={{ flex: "1 1 auto", minHeight: rootFontSizeInPX * 2.5, maxHeight: rootFontSizeInPX * 2.5 }} teamInfo={targetCompanyAlias} />
                            </span>
                        )}
                    </div>
                </span>
            ),
        });

        tabConfigs.push({
            id: "app",
            label: (
                <Typography>
                    <Trans>App</Trans>
                </Typography>
            ),
            component: (
                <span className={classes.tabPanel}>
                    <div style={{ width: "100%" }} onClick={targetCompanyAlias ? this.onToggleAppSelector : undefined}>
                        {!targetApp && (
                            <span style={{ width: "100%", padding: "0.3125em", overflow: "hidden" }}>
                                <Typography style={{ flex: "0 0 auto", fontStyle: "italic", marginLeft: "auto", marginRight: "auto", paddingLeft: "0.3125em", paddingRight: "0.3125em" }} noWrap>
                                    <Trans>No App Selected</Trans>
                                </Typography>

                                {targetCompanyAlias && (
                                    <IconButton id={"toggle-unified-selector"} style={{ flex: "0 0 auto" }}>
                                        {!appSelectorOpen && <ChevronDownIcon />}
                                        {appSelectorOpen && <ChevronUpIcon />}
                                    </IconButton>
                                )}
                            </span>
                        )}

                        {targetApp && (
                            <span style={{ width: "100%", overflow: "hidden" }}>
                                <AppMenuItem style={{ flex: "1 1 auto", minHeight: rootFontSizeInPX * 2.5, maxHeight: rootFontSizeInPX * 2.5 }} appInfo={targetApp} />

                                {targetCompanyAlias && (
                                    <IconButton id={"toggle-unified-selector"} style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                        {!appSelectorOpen && <ChevronDownIcon />}
                                        {appSelectorOpen && <ChevronUpIcon />}
                                    </IconButton>
                                )}
                            </span>
                        )}
                    </div>

                    <AppSelector open={appSelectorOpen} itemHeight={rootFontSizeInPX * 2.5} itemCount={Math.min(availableApps.length, APP_ITEM_COUNT)} onToggleOpen={this.onToggleAppSelector} onAppChange={this.onAppChange} />
                </span>
            ),
        });

        if (availablePrivileges.length === 0 || hasReadAccess(availablePrivileges, "MONITOR_USER_SECTION")) {
            tabConfigs.push({
                id: "user",
                label: (
                    <Typography>
                        <Trans>User</Trans>
                    </Typography>
                ),
                component: (
                    <span className={classes.tabPanel}>
                        <div style={{ width: "100%" }}>
                            {!targetPlayerSummary && (
                                <span style={{ width: "100%", padding: "0.3125em", overflow: "hidden" }}>
                                    <Typography style={{ flex: "0 0 auto", fontStyle: "italic", marginLeft: "auto", marginRight: "auto", paddingLeft: "0.3125em", paddingRight: "0.3125em" }} noWrap>
                                        <Trans>Select a user</Trans>
                                    </Typography>
                                </span>
                            )}

                            {targetPlayerSummary && (
                                <span style={{ width: "100%", overflow: "hidden" }}>
                                    <PlayerMenuItem style={{ flex: "1 1 auto", minHeight: rootFontSizeInPX * 2.5, maxHeight: rootFontSizeInPX * 2.5 }} playerSummary={targetPlayerSummary} />
                                </span>
                            )}
                        </div>
                    </span>
                ),
            });
        }

        return tabConfigs;
    };

    handleOpenProfile = (event: React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();

        this.props.onToggleProfileOpen();

        if (this.props.open && !this.props.pinned) {
            this.props.onToggleOpen();
        }
    };

    render() {
        const { classes, session, currentUser, definitions, pinned } = this.props;
        const { activeTabIndex } = this.state;

        const context = session.isSuper
            ? activeTabIndex === 0
                ? "super"
                : activeTabIndex === 1
                ? "team"
                : activeTabIndex === 2
                ? "app"
                : activeTabIndex === 3
                ? "user"
                : null
            : activeTabIndex === 0
            ? "team"
            : activeTabIndex === 1
            ? "app"
            : activeTabIndex === 2
            ? "user"
            : null;

        // Compute the base font-size and line height in PX.
        const rootFontSizeAndLineHeight = UnitUtils.determineBaseFontSizeAndLineHeightAsNumber();
        const rootFontSizeInPX = rootFontSizeAndLineHeight.fontSize;

        return (
            <div id="navigation-blade" className={classes.root}>
                <Tabs
                    orientation={"horizontal"}
                    className={classnames(
                        classes.tabs,
                        session.isSuper ? "super" : null,
                        session.isSuper
                            ? activeTabIndex === 0
                                ? "super-team"
                                : activeTabIndex === 1
                                ? "super-alias"
                                : activeTabIndex === 2
                                ? "super-app"
                                : activeTabIndex === 3
                                ? "super-user"
                                : null
                            : activeTabIndex === 0
                            ? "team"
                            : activeTabIndex === 1
                            ? "app"
                            : activeTabIndex === 2
                            ? "user"
                            : null
                    )}
                    configs={this.prepareTabConfigs(rootFontSizeInPX)}
                    value={activeTabIndex}
                    onTabChanged={this.setActiveTab}
                    scrollButtons={false}
                />

                <IconButton id={"toggle-pinned"} className={classes.togglePinnedIcon} onClick={() => this.props.onTogglePinned()}>
                    <FontAwesomeIcon icon={faThumbtack} style={{ width: "0.8em", height: "0.8em", transform: pinned ? "rotate(45deg)" : undefined }} />
                </IconButton>

                <IconButton id={"toggle-open"} className={classes.toggleOpenIcon} onClick={() => this.props.onToggleOpen()}>
                    <CloseIcon style={{ width: "0.8em", height: "0.8em" }} />
                </IconButton>

                {context && (
                    <>
                        <Divider className={classes.divider} />

                        <NavigatorRouteTree context={context} definitions={definitions} itemHeight={2} onNavigation={this.onNavigation} />
                    </>
                )}

                {currentUser && (
                    <div id={"profile"} style={{ flex: "0 0 auto" }} onClick={this.handleOpenProfile}>
                        {currentUser?.email && <img style={{ flex: "0 0 auto" }} alt={"user-avatar"} src={"https://s.gravatar.com/avatar/" + md5(currentUser.email) + ".jpg?s=96&d=mm"} />}

                        <Typography style={{ flex: "1 1 auto", marginLeft: "0.3125em", overflow: "hidden" }} noWrap>
                            {currentUser?.fullName}
                        </Typography>

                        <IconButton id={"profile-expanded"} style={{ flex: "0 0 auto" }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--navigation-background-color, inherit)",
            color: "var(--navigation-color, inherit)",
            borderColor: "var(--navigation-border-color, inherit)",

            overflow: "hidden",

            position: "relative",

            "& > div#profile": {
                display: "flex",
                alignItems: "center",

                minHeight: "2.875em",
                maxHeight: "2.875em",

                padding: "0.3125em",

                cursor: "pointer",

                borderColor: "inherit",

                borderTopStyle: "solid",

                "& > img": {
                    height: "100%",

                    borderRadius: "0.25em",
                    overflow: "hidden",
                },
            },

            [theme.breakpoints.up("sm")]: {
                "& > div#profile": {
                    display: "none",
                },
            },
        },

        togglePinnedIcon: {
            position: "absolute",

            top: "0.25em",
            right: 0,

            marginLeft: "0.25em",
            marginRight: "0.25em",

            display: "flex",
            alignItems: "center",

            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        toggleOpenIcon: {
            position: "absolute",

            top: "0.25em",
            right: 0,

            marginLeft: "0.25em",
            marginRight: "0.25em",

            display: "flex",
            alignItems: "center",

            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },

        tabs: {
            flex: "0 0 auto",

            "& .MuiTabs-root": {
                minHeight: "unset",
                maxHeight: "unset",

                "& .MuiTab-root": {
                    minHeight: "unset",
                    maxHeight: "unset",

                    minWidth: "5em",
                    maxWidth: "5em",

                    "&:last-child": {
                        marginRight: "auto",
                    },
                },
            },

            "&.super": {
                "&.super-team .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-super-context-color, inherit)",
                },
                "&.super-alias .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-team-context-color, inherit)",
                },
                "&.super-app .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-app-context-color, inherit)",
                },
                "&.super-user .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-user-context-color, inherit)",
                },

                "& .MuiTabs-root .MuiTab-root .MuiTypography-root": {
                    textTransform: "none",
                },

                "& .MuiTabs-root #tab-0.MuiTab-root": {
                    minWidth: 0,

                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-super-context-background-color, inherit)",
                        color: "var(--navigation-super-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-super-context-color, inherit)",
                    },
                },

                "& .MuiTabs-root #tab-1.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-team-context-background-color, inherit)",
                        color: "var(--navigation-team-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-team-context-color, inherit)",
                    },
                },

                "& .MuiTabs-root #tab-2.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-app-context-background-color, inherit)",
                        color: "var(--navigation-app-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-app-context-color, inherit)",
                    },
                },

                "& .MuiTabs-root #tab-3.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-user-context-background-color, inherit)",
                        color: "var(--navigation-user-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-user-context-color, inherit)",
                    },
                },
            },

            "&:not(.super)": {
                "&.team .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-team-context-color, inherit)",
                },
                "&.app .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-app-context-color, inherit)",
                },
                "&.user .MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "var(--navigation-user-context-color, inherit)",
                },

                "& .MuiTabs-root .MuiTab-root .MuiTypography-root": {
                    textTransform: "none",
                },

                "& .MuiTabs-root #tab-0.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-team-context-background-color, inherit)",
                        color: "var(--navigation-team-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-team-context-color, inherit)",
                    },
                },

                "& .MuiTabs-root #tab-1.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-app-context-background-color, inherit)",
                        color: "var(--navigation-app-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-app-context-color, inherit)",
                    },
                },

                "& .MuiTabs-root #tab-2.MuiTab-root": {
                    "&.Mui-selected": {
                        backgroundColor: "var(--navigation-user-context-background-color, inherit)",
                        color: "var(--navigation-user-context-color, inherit)",
                    },

                    "&:hover": {
                        color: "var(--navigation-user-context-color, inherit)",
                    },
                },
            },
        },
        tabPanel: {
            width: "100%",
            minHeight: "2.5em",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",

            "& > div:first-child": {
                flex: "0 0 auto",

                display: "flex",
                alignItems: "stretch",

                "& > span": {
                    minHeight: "2.5em",
                    maxHeight: "2.5em",

                    flex: "1 1 auto",
                    display: "flex",
                    alignItems: "center",
                },
            },
        },

        divider: {
            backgroundColor: "var(--navigation-border-color, inherit)",
            marginBottom: "0.5em",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Navigation)));
