import React from "react";
import { Trans } from "@lingui/macro";
import { FieldType } from "./FieldWrapper";

interface Options {
    disabled?: boolean;
    required?: boolean;

    minValue?: number | Date;
    maxValue?: number | Date;

    minLength?: number;
    maxLength?: number;

    pattern?: RegExp;
    patternMessage?: string;

    freeSolo?: boolean;

    mode?: "json" | "javascript";

    custom?: (value: any) => React.ReactNode;
}

const validateCheckboxField = (value: boolean | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : false;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    return null;
};

const validateSwitchField = (value: boolean | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : false;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    return null;
};

const validateColorField = (value: boolean | null, options: Options) => {
    if (options.disabled) return null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(value != null ? value : null);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (value == null) {
            return <Trans>Required</Trans>;
        }
    }

    return null;
};

const validateNumberField = (value: number | null, options: Options) => {
    const valueAsString = value != null ? "" + value : "";

    if (options.disabled) return null;

    const currentValueAsNumber = valueAsString.trim() !== "" ? (valueAsString.includes(".") ? Number.parseFloat(valueAsString) : Number.parseInt(valueAsString)) : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValueAsNumber);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValueAsNumber == null || Number.isNaN(currentValueAsNumber)) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform min/max value checks.
    const minValue = options.minValue != null && typeof options.minValue === "number" ? (options.minValue as number) : null;
    const maxValue = options.maxValue != null && typeof options.maxValue === "number" ? (options.maxValue as number) : null;
    if ((minValue != null || maxValue != null) && currentValueAsNumber != null) {
        if (minValue != null && maxValue != null && minValue > maxValue) {
            return <Trans>The minimum value cannot be greater than the maximum value.</Trans>;
        }

        if (minValue != null && maxValue != null && minValue === maxValue) {
            if (currentValueAsNumber !== minValue) {
                return <Trans>Must be {minValue} (exactly)</Trans>;
            }
        } else if (minValue != null && maxValue != null && minValue !== maxValue) {
            if (currentValueAsNumber < minValue || currentValueAsNumber > maxValue) {
                return (
                    <Trans>
                        Must be between {options.minValue} and {options.maxValue} (inclusive)
                    </Trans>
                );
            }
        } else if (minValue != null) {
            if (currentValueAsNumber < minValue) {
                return <Trans>Must be at least {options.minValue}</Trans>;
            }
        } else if (maxValue != null) {
            if (currentValueAsNumber > maxValue) {
                return <Trans>Must be at most {maxValue}</Trans>;
            }
        } else {
            // No value restrictions.
        }
    }

    return null;
};

const validateDateField = (value: Date | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue === null) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform valid date check.
    if (currentValue != null && Number.isNaN(currentValue.getTime())) {
        return <Trans>Invalid Date</Trans>;
    }

    // Perform min/max value checks.
    const minValue = options.minValue != null && typeof options.minValue !== "number" ? (options.minValue as Date) : null;
    const maxValue = options.maxValue != null && typeof options.maxValue !== "number" ? (options.maxValue as Date) : null;
    if (minValue != null || maxValue != null) {
        if (minValue != null && maxValue != null && minValue === maxValue) {
            if (currentValue != null && currentValue !== minValue) {
                return <Trans>Must be {minValue}</Trans>;
            }
        } else if (minValue != null && maxValue != null && minValue !== maxValue) {
            if (currentValue != null && (currentValue < minValue || currentValue > maxValue)) {
                return (
                    <Trans>
                        Must be between {minValue} and {maxValue}
                    </Trans>
                );
            }
        } else if (minValue != null) {
            if (currentValue != null && currentValue < minValue) {
                return <Trans>Must be at least {minValue}</Trans>;
            }
        } else if (maxValue != null) {
            if (currentValue != null && currentValue > maxValue) {
                return <Trans>Must be at most {maxValue}</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    return null;
};

const validateTimeField = (value: Date | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue === null) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform valid date check.
    if (currentValue != null && Number.isNaN(currentValue.getTime())) {
        return <Trans>Invalid Time</Trans>;
    }

    // Perform min/max value checks.
    const minValue = options.minValue != null && typeof options.minValue !== "number" ? (options.minValue as Date) : null;
    const maxValue = options.maxValue != null && typeof options.maxValue !== "number" ? (options.maxValue as Date) : null;
    if (minValue != null || maxValue != null) {
        if (minValue != null && maxValue != null && minValue === maxValue) {
            if (currentValue != null && currentValue !== minValue) {
                return <Trans>Must be {minValue}</Trans>;
            }
        } else if (minValue != null && maxValue != null && minValue !== maxValue) {
            if (currentValue != null && (currentValue < minValue || currentValue > maxValue)) {
                return (
                    <Trans>
                        Must be between {minValue} and {maxValue}
                    </Trans>
                );
            }
        } else if (minValue != null) {
            if (currentValue != null && currentValue < minValue) {
                return <Trans>Must be at least {minValue}</Trans>;
            }
        } else if (maxValue != null) {
            if (currentValue != null && currentValue > maxValue) {
                return <Trans>Must be at most {maxValue}</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    return null;
};

const validateDateTimeField = (value: Date | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue === null) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform valid date check.
    if (currentValue != null && Number.isNaN(currentValue.getTime())) {
        return <Trans>Invalid Date/Time</Trans>;
    }

    // Perform min/max value checks.
    const minValue = options.minValue != null && typeof options.minValue !== "number" ? (options.minValue as Date) : null;
    const maxValue = options.maxValue != null && typeof options.maxValue !== "number" ? (options.maxValue as Date) : null;
    if (minValue != null || maxValue != null) {
        if (minValue != null && maxValue != null && minValue === maxValue) {
            if (currentValue != null && currentValue !== minValue) {
                return <Trans>Must be {minValue}</Trans>;
            }
        } else if (minValue != null && maxValue != null && minValue !== maxValue) {
            if (currentValue != null && (currentValue < minValue || currentValue > maxValue)) {
                return (
                    <Trans>
                        Must be between {minValue} and {maxValue}
                    </Trans>
                );
            }
        } else if (minValue != null) {
            if (currentValue != null && currentValue < minValue) {
                return <Trans>Must be at least {minValue}</Trans>;
            }
        } else if (maxValue != null) {
            if (currentValue != null && currentValue > maxValue) {
                return <Trans>Must be at most {maxValue}</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    return null;
};

const validateTextField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue.trim().length === 0) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform min/max length checks.
    if (options.minLength != null || options.maxLength != null) {
        if (options.minLength != null && options.maxLength != null && options.minLength === options.maxLength) {
            if (currentValue.trim().length !== options.minLength) {
                return <Trans>Must be {options.minLength} characters in length</Trans>;
            }
        } else if (options.minLength != null && options.maxLength != null && options.minLength !== options.maxLength) {
            if (currentValue.trim().length < options.minLength || currentValue.trim().length > options.maxLength) {
                return (
                    <Trans>
                        Must be between {options.minLength} and {options.maxLength} characters in length
                    </Trans>
                );
            }
        } else if (options.minLength != null) {
            if (currentValue.trim().length < options.minLength) {
                return <Trans>Must be at least {options.minLength} characters in length</Trans>;
            }
        } else if (options.maxLength != null) {
            if (currentValue.trim().length > options.maxLength) {
                return <Trans>Must be at most {options.maxLength} characters in length</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    // Perform pattern check.
    if (options.pattern) {
        if (currentValue.length > 0 && !currentValue.match(options.pattern)) {
            return <Trans>Invalid: {options.patternMessage ? options.patternMessage : options.pattern.toString()}</Trans>;
        }
    }

    return null;
};

const validateTextAreaField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue.trim().length === 0) {
            return <Trans>Required</Trans>;
        }
    }

    if (options) {
        // Perform min/max length checks.
        if (options.minLength != null || options.maxLength != null) {
            if (options.minLength != null && options.maxLength != null && options.minLength === options.maxLength) {
                if (currentValue.trim().length !== options.minLength) {
                    return <Trans>Must be {options.minLength} characters in length</Trans>;
                }
            } else if (options.minLength != null && options.maxLength != null && options.minLength !== options.maxLength) {
                if (currentValue.trim().length < options.minLength || currentValue.trim().length > options.maxLength) {
                    return (
                        <Trans>
                            Must be between {options.minLength} and {options.maxLength} characters in length
                        </Trans>
                    );
                }
            } else if (options.minLength != null) {
                if (currentValue.trim().length < options.minLength) {
                    return <Trans>Must be at least {options.minLength} characters in length</Trans>;
                }
            } else if (options.maxLength != null) {
                if (currentValue.trim().length > options.maxLength) {
                    return <Trans>Must be at most {options.maxLength} characters in length</Trans>;
                }
            } else {
                // No length restrictions.
            }
        }

        // Perform pattern check.
        if (options.pattern) {
            if (currentValue.length > 0 && !currentValue.match(options.pattern)) {
                return <Trans>Invalid: {options.patternMessage ? options.patternMessage : options.pattern.toString()}</Trans>;
            }
        }
    }

    return null;
};

const validateMaskedInputField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) return customError;
    }

    // Perform required check.
    if (options.required && currentValue.trim().length === 0) return <Trans>Required</Trans>;

    // Perform min/max length checks.
    if (options.minLength != null || options.maxLength != null) {
        if (options.minLength != null && options.maxLength != null && options.minLength === options.maxLength) {
            if (currentValue.trim().length !== options.minLength) {
                return <Trans>Must be {options.minLength} characters in length</Trans>;
            }
        } else if (options.minLength != null && options.maxLength != null && options.minLength !== options.maxLength) {
            if (currentValue.trim().length < options.minLength || currentValue.trim().length > options.maxLength) {
                return (
                    <Trans>
                        Must be between {options.minLength} and {options.maxLength} characters in length
                    </Trans>
                );
            }
        } else if (options.minLength != null) {
            if (currentValue.trim().length < options.minLength) {
                return <Trans>Must be at least {options.minLength} characters in length</Trans>;
            }
        } else if (options.maxLength != null) {
            if (currentValue.trim().length > options.maxLength) {
                return <Trans>Must be at most {options.maxLength} characters in length</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    // Perform pattern check.
    if (options.pattern) {
        if (currentValue.length > 0 && !currentValue.match(options.pattern)) {
            return <Trans>Invalid: {options.patternMessage ? options.patternMessage : options.pattern.toString()}</Trans>;
        }
    }

    return null;
};

const validatePasswordField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue.trim().length === 0) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform min/max length checks.
    if (options.minLength != null || options.maxLength != null) {
        if (options.minLength != null && options.maxLength != null && options.minLength === options.maxLength) {
            if (currentValue.trim().length !== options.minLength) {
                return <Trans>Must be {options.minLength} characters in length</Trans>;
            }
        } else if (options.minLength != null && options.maxLength != null && options.minLength !== options.maxLength) {
            if (currentValue.trim().length < options.minLength || currentValue.trim().length > options.maxLength) {
                return (
                    <Trans>
                        Must be between {options.minLength} and {options.maxLength} characters in length
                    </Trans>
                );
            }
        } else if (options.minLength != null) {
            if (currentValue.trim().length < options.minLength) {
                return <Trans>Must be at least {options.minLength} characters in length</Trans>;
            }
        } else if (options.maxLength != null) {
            if (currentValue.trim().length > options.maxLength) {
                return <Trans>Must be at most {options.maxLength} characters in length</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    // Perform pattern check.
    if (options.pattern) {
        if (currentValue.length > 0 && !currentValue.match(options.pattern)) {
            return <Trans>Invalid: {options.patternMessage ? options.patternMessage : options.pattern.toString()}</Trans>;
        }
    }

    return null;
};

const validateSelectField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue == null) {
            return <Trans>Required</Trans>;
        }
    }

    return null;
};

const validateComboboxField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue == null) {
            return <Trans>Required</Trans>;
        }
    }

    // Perform pattern check for freeSolo Compbobox.
    if (options.pattern && options.freeSolo && typeof currentValue === "string") {
        if (currentValue.length > 0 && !currentValue.match(options.pattern)) {
            return <Trans>Invalid: {options.patternMessage ? options.patternMessage : options.pattern.toString()}</Trans>;
        }
    }

    // Perform min/max length checks.
    if (currentValue && options.freeSolo && (options.minLength != null || options.maxLength != null)) {
        if (options.minLength != null && options.maxLength != null && options.minLength === options.maxLength) {
            if (currentValue.trim().length !== options.minLength) {
                return <Trans>Must be {options.minLength} characters in length</Trans>;
            }
        } else if (options.minLength != null && options.maxLength != null && options.minLength !== options.maxLength) {
            if (currentValue.trim().length < options.minLength || currentValue.trim().length > options.maxLength) {
                return (
                    <Trans>
                        Must be between {options.minLength} and {options.maxLength} characters in length
                    </Trans>
                );
            }
        } else if (options.minLength != null) {
            if (currentValue.trim().length < options.minLength) {
                return <Trans>Must be at least {options.minLength} characters in length</Trans>;
            }
        } else if (options.maxLength != null) {
            if (currentValue.trim().length > options.maxLength) {
                return <Trans>Must be at most {options.maxLength} characters in length</Trans>;
            }
        } else {
            // No length restrictions.
        }
    }

    return null;
};

const validateAceField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue.trim().length === 0) {
            return <Trans>Required</Trans>;
        }
    }

    return null;
};

const validateMonacoField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : "";

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue.trim().length === 0) {
            return <Trans>Required</Trans>;
        }
    }

    // if (options.mode === "json") {
    //     let isValid = true;

    //     try {
    //         const parsed = JSON.parse(currentValue);

    //         if (typeof parsed !== "object") isValid = false;
    //     } catch (err) {
    //         isValid = false;
    //     }

    //     if (!isValid) {
    //         return <Trans>Invalid JSON</Trans>;
    //     }
    // }

    return null;
};

const validateRadioButtonField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue == null) {
            return <Trans>Required</Trans>;
        }
    }

    return null;
};

const validateScriptPickerField = (value: string | null, options: Options) => {
    if (options.disabled) return null;

    const currentValue = value != null ? value : null;

    // Perform custom validation check.
    if (options.custom) {
        const customError = options.custom(currentValue);

        if (customError) {
            return customError;
        }
    }

    // Perform required check.
    if (options.required) {
        if (currentValue == null) {
            return <Trans>Required</Trans>;
        }
    }

    return null;
};

export const validate = (type: FieldType | null | undefined, _name: string, value: any, options: Options) => {
    // console.log("Validating Field", name, type, value, options);

    if (!type) return null;

    switch (type) {
        case "checkbox":
            return validateCheckboxField(value, options);
        case "switch":
            return validateSwitchField(value, options);
        case "color":
            return validateColorField(value, options);
        case "number":
            return validateNumberField(value, options);
        case "date":
            return validateDateField(value, options);
        case "time":
            return validateTimeField(value, options);
        case "datetime":
            return validateDateTimeField(value, options);
        case "text":
            return validateTextField(value, options);
        case "textarea":
            return validateTextAreaField(value, options);
        case "masked":
            return validateMaskedInputField(value, options);
        case "password":
            return validatePasswordField(value, options);
        case "select":
            return validateSelectField(value, options);
        case "combobox":
            return validateComboboxField(value, options);
        case "ace":
            return validateAceField(value, options);
        case "monaco":
            return validateMonacoField(value, options);
        case "radiobutton":
            return validateRadioButtonField(value, options);
        case "cloud-code-script":
            return validateScriptPickerField(value, options);
        default:
        // Do nothing.
    }

    return null;
};

interface FormField {
    type?: FieldType | null;
    name: string;
    value?: any;
    options?: Options | null;
}

interface FieldError {
    name: string;
    error: React.ReactNode;
}

export class FormValidator {
    private isFirstSubmission: boolean = true;

    private errors: FieldError[] = [];

    validate(fields: FormField[]) {
        this.errors = [];

        fields.forEach((field) => {
            if (field.type && field.options) {
                const error = validate(field.type, field.name, field.value, field.options);

                if (error) {
                    this.errors.push({ name: field.name, error: error });
                }
            }
        });

        this.isFirstSubmission = false;

        return this.errors.length === 0;
    }

    getError(name: string) {
        if (this.isFirstSubmission) return null;

        const error = this.errors.find((item) => item.name === name) || null;

        if (error) return error.error;
        else return null;
    }
}
