// Import libraries.
import React from "react";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";

// Import types.
import PortalState from "types/store";

// Import utils.
import ClipboardUtils from "utils/Clipboard";

const AppVersion: React.FunctionComponent = () => {
    const applicationInformation = useSelector((state: PortalState) => state.applicationInformation);

    return (
        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Tooltip alwaysShow arrow title={<Trans>Click to copy Portal Version</Trans>}>
                <Typography id="portal-version" style={{ fontSize: "0.75em", cursor: "pointer" }} noWrap onClick={() => ClipboardUtils.writeText(`${applicationInformation.prefix}${applicationInformation.version}-${applicationInformation.build}`)}>
                    <Trans>
                        Portal Version: {applicationInformation.prefix}
                        {applicationInformation.version}-{applicationInformation.build}
                    </Trans>
                </Typography>
            </Tooltip>

            <Tooltip alwaysShow arrow title={<Trans>Click to copy Server Version</Trans>}>
                <Typography id="server-version" style={{ fontSize: "0.75em", cursor: "pointer" }} onClick={() => ClipboardUtils.writeText(applicationInformation.platformVersion)}>
                    <Trans>Server Version: {applicationInformation.platformVersion}</Trans>
                </Typography>
            </Tooltip>

            <Typography style={{ fontSize: "0.75em" }}>
                <Trans>&copy; 2024 bitHeads Inc.</Trans>
            </Typography>
        </div>
    );
};

export default AppVersion;
