import { editor } from "monaco-editor";

import CloudCodeFileList, { CloudCodeScriptComposite, CloudCodeScriptVersion, CloudCodeScriptWithContent } from "types/models/CloudCodeFileList";

export interface CloudCodeEditorAction {
    type:
        | "CLOUD_CODE_EDITOR_RESET"
        | "CLOUD_CODE_EDITOR_SET_FILE_LIST"
        | "CLOUD_CODE_EDITOR_SET_ARCHIVED_SCRIPT_VERSIONS"
        | "CLOUD_CODE_EDITOR_SET_SCRIPTS_WITH_CONTENT"
        | "CLOUD_CODE_EDITOR_SET_OPEN_SCRIPTS_WITH_CONTENT"
        | "CLOUD_CODE_EDITOR_SET_RENDERED_SCRIPTS"
        | "CLOUD_CODE_EDITOR_SET_VIEW_STATE";

    payload?:
        | CloudCodeFileList
        | { scriptId: string; archivedScriptVersions?: CloudCodeScriptVersion[] | null }
        | { scriptId: string; scriptsWithContent?: CloudCodeScriptWithContent[] | null }
        | { scriptId: string; scriptWithContent?: CloudCodeScriptWithContent | null }
        | { scriptId: string; renderedScript?: CloudCodeScriptComposite | null }
        | { scriptId: string; viewState?: editor.ICodeEditorViewState | null }
        | null;
}

export const CLOUD_CODE_EDITOR_RESET = (): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_RESET",
    };
};

export const CLOUD_CODE_EDITOR_SET_FILE_LIST = (fileList?: CloudCodeFileList | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_FILE_LIST",
        payload: fileList,
    };
};

export const CLOUD_CODE_EDITOR_SET_ARCHIVED_SCRIPT_VERSIONS = (scriptId: string, archivedScriptVersions?: CloudCodeScriptVersion[] | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_ARCHIVED_SCRIPT_VERSIONS",
        payload: {
            scriptId: scriptId,
            archivedScriptVersions: archivedScriptVersions,
        },
    };
};

export const CLOUD_CODE_EDITOR_SET_SCRIPTS_WITH_CONTENT = (scriptId: string, scriptsWithContent?: CloudCodeScriptWithContent[] | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_SCRIPTS_WITH_CONTENT",
        payload: {
            scriptId: scriptId,
            scriptsWithContent: scriptsWithContent,
        },
    };
};

export const CLOUD_CODE_EDITOR_SET_OPEN_SCRIPTS_WITH_CONTENT = (scriptId: string, scriptWithContent?: CloudCodeScriptWithContent | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_OPEN_SCRIPTS_WITH_CONTENT",
        payload: {
            scriptId: scriptId,
            scriptWithContent: scriptWithContent,
        },
    };
};

export const CLOUD_CODE_EDITOR_SET_RENDERED_SCRIPTS = (scriptId: string, renderedScript?: CloudCodeScriptComposite | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_RENDERED_SCRIPTS",
        payload: {
            scriptId: scriptId,
            renderedScript: renderedScript,
        },
    };
};

export const CLOUD_CODE_EDITOR_SET_VIEW_STATE = (scriptId: string, viewState?: editor.ICodeEditorViewState | null): CloudCodeEditorAction => {
    return {
        type: "CLOUD_CODE_EDITOR_SET_VIEW_STATE",
        payload: {
            scriptId: scriptId,
            viewState: viewState,
        },
    };
};
