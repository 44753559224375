// Import libraries.
import { select, put, all, takeLatest, call } from "redux-saga/effects";
import { Trans } from "@lingui/macro";
import { toast } from "utils/Toast";

// Import types.
import { CookieConsentLevel } from "utils/CookieConsent";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import AppInfo, { processAppInfo } from "types/models/AppInfo";
import { processPlayerSummary } from "types/models/PlayerSummaryInfo";

// Import redux actions.
import { SET_APPLICATION_INFORMATION } from "store/actions/app";
import { SET_SESSION } from "store/actions/session";
import { SET_AVAILABLE_APPS } from "store/actions/availableApps";
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";
import { CLOUD_CODE_EDITOR_RESET } from "store/actions/cloudCodeEditor";

// Import redux sagas.
import { saveSession } from "./sessionSagas";
import { populateAvailablePrivileges } from "./privilegeSagas";
import { populateScreenSettings } from "./screenSettingSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import LocalStorageUtils from "utils/LocalStorage";

interface PopulateAvailableApps {
    type: "app.populateAvailableApps";
}

interface SetAppId {
    type: "app.setAppId";
    payload: {
        appId: string | null;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

interface SetPlayerId {
    type: "app.setPlayerId";
    payload: {
        playerId: string | null;
        skipPlayerLockCheck?: boolean;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

interface CheckLiveLock {
    type: "app.checkLiveLock";
    payload: {
        disableLiveLock?: boolean | null;
        privilegeId?: string | null;
    };
}

interface ToggleLiveLock {
    type: "app.toggleLiveLock";
    payload: {
        appName?: string | null;
        isLiveLocked?: boolean | null;
    };
}

interface CheckPlayerLock {
    type: "app.checkPlayerLock";
}

interface TogglePlayerLock {
    type: "app.togglePlayerLock";
}

interface DismissSystemMessage {
    type: "app.dismissSystemMessage";
}

// Attempts to populate the available apps that are accessible by the current user.
export function* populateAvailableApps(_action?: PopulateAvailableApps) {
    // Get the current session.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    try {
        console.log("Populating Available Apps...");

        if ((session.isSuper && session.companyIdAlias) || (!session.isSuper && session.companyId)) {
            const response: HttpResponse = yield Http.GET("admin/serveradmin/team-apps-read");
            if (Http.isStatusOk(response) && Array.isArray(response.data)) {
                const newAvailableApps = response.data.map((item: any) => processAppInfo(item));

                // Sort newly available apps by name.
                newAvailableApps.sort((a, b) => a.appName.localeCompare(b.appName));

                yield put(SET_AVAILABLE_APPS(newAvailableApps));

                // Get the new target app.
                const newApp = newAvailableApps.find((item) => item.appId === session.appId) || null;

                // If target app no longer exists (or the user no longer has access to the app), clean up the session.
                if (!newApp && session.appId) {
                    console.warn("APP NO LONGER ACCESSIBLE", session.appId);

                    session.appId = null;
                    session.playerId = null;
                    session.playerSummary = null;
                    session.isLiveLocked = false;
                    session.disableLiveLock = false;
                    session.forceReselectionOfCompanyId = true;

                    // Update the session in the redux store.
                    yield put(SET_SESSION(CloneUtils.clone(session)));
                    yield call(saveSession);
                }

                // If the target app still exists, populate the available privileges, screen settings and live lock state.
                if (newApp) {
                    yield all([call(populateAvailablePrivileges), call(populateScreenSettings), call(checkLiveLock, { type: "app.checkLiveLock", payload: { disableLiveLock: true, privilegeId: "APP_DASHBOARD" } })]);

                    // Set the playerId if it exists, such as when a player is already selected and performs a hard refresh, or if a portal triggers a refresh.
                    if (session.playerId) {
                        yield call(setPlayerId, { type: "app.setPlayerId", payload: { playerId: session.playerId } });
                    }
                }

                return true;
            }
        }
    } catch (error: any) {
        console.error("populateAvailableApps - ERROR", error);
    }

    yield put(SET_AVAILABLE_APPS([]));

    return false;
}

// Attempts to set the current user's app id.
export function* setAppId(action: SetAppId) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current user's profile id.
    const getCurrentUserProfileId = (state: PortalState): string | null => (state.currentUser ? state.currentUser.profileId : null);
    const currentUserProfileId: string | null = yield select(getCurrentUserProfileId);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === action.payload.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    try {
        console.log("Selecting App", action.payload.appId);

        if (!applicationInformation.loadingBasicState) {
            // Indicate that we are now loading basic state.
            applicationInformation.loadingBasicState = true;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Attempt to set the current app id on the server.
        const response: HttpResponse = yield Http.POST("admin/development/setgame", { gameId: action.payload.appId }, null, Http.JSON_HEADERS);
        if (Http.isStatusOk(response)) {
            // Set various fields on the current session.
            session.appId = targetApp.appId;
            session.playerId = null;
            session.playerSummary = null;
            session.isLiveLocked = targetApp.isLive === true;
            session.disableLiveLock = false;

            // Update the session in the redux store.
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);

            // Reset the Cloud Code Editor state.
            yield put(CLOUD_CODE_EDITOR_RESET());

            // Load the available privileges, screen settings and live lock state.
            yield all([call(populateAvailablePrivileges), call(populateScreenSettings), call(checkLiveLock, { type: "app.checkLiveLock", payload: { disableLiveLock: true, privilegeId: "APP_DASHBOARD" } })]);

            // Update the "lastgame_<profileId>" in local storage.
            if (session.isSuper) {
                LocalStorageUtils.setItem(
                    "lastgame_" + currentUserProfileId + "_" + session.companyIdAlias,
                    JSON.stringify({
                        gameId: session.appId,
                    }),
                    CookieConsentLevel.FUNCTIONALITY
                );
            } else {
                LocalStorageUtils.setItem(
                    "lastgame_" + currentUserProfileId + "_" + session.companyId,
                    JSON.stringify({
                        gameId: session.appId,
                    }),
                    CookieConsentLevel.FUNCTIONALITY
                );
            }

            return true;
        }
    } catch (error: any) {
        console.error("setAppId - ERROR", error);
    } finally {
        console.log("Done Selecting App!");

        // If the optional path and history are both present, push the requested path onto the history.
        if (action.payload.history && action.payload.path) {
            if (action.payload.history.location.pathname !== action.payload.path) {
                action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            } else {
                action.payload.history.replace(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            }
        }

        if (applicationInformation.loadingBasicState) {
            // Indicate that we are no longer loading basic state.
            applicationInformation.loadingBasicState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    }

    // Set various fields on the current session.
    delete session.appId;
    delete session.playerId;
    delete session.playerSummary;
    delete session.isLiveLocked;
    delete session.disableLiveLock;

    // Update the session in the redux store.
    yield put(SET_SESSION(CloneUtils.clone(session)));
    yield call(saveSession);

    // Reset the Cloud Code Editor state.
    yield put(CLOUD_CODE_EDITOR_RESET());

    // Clear the available privileges and screen settings.
    yield put(SET_AVAILABLE_PRIVILEGES([]));
    yield put(SET_SCREEN_SETTINGS([]));

    return false;
}

// Attempts to set the current user's player id (for user montioring).
export function* setPlayerId(action: SetPlayerId) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    let playerFound = false;

    try {
        console.log("Selecting Player", action.payload.playerId);

        if (action.payload.playerId) {
            const response: HttpResponse = yield Http.GET("admin/monitoring/playerSummaryByPlayerId", { playerId: action.payload.playerId }, Http.JSON_HEADERS);

            if (Http.isStatusOk(response) && response.data && Object.keys(response.data).length > 0) {
                playerFound = true;

                // Set the currently selected player id and associated player summary..
                session.playerId = action.payload.playerId;
                session.playerSummary = processPlayerSummary(response.data);

                // Initialize the player lock state accordingly.
                if (targetApp.isLive) {
                    if (!action.payload.skipPlayerLockCheck) {
                        session.isPlayerLocked = true;
                    }
                } else {
                    delete session.isPlayerLocked;
                }

                yield put(SET_SESSION(session));
                yield call(saveSession);

                // If we are performing the player lock check, trigger the checkPlayerLock saga.
                if (targetApp.isLive && !action.payload.skipPlayerLockCheck) {
                    yield call(checkPlayerLock, { type: "app.checkPlayerLock" });
                }
            } else {
                toast.error(<Trans>User not found: {action.payload.playerId}</Trans>);

                // Clear the currently selected player id, player summary and player lock state.
                session.playerId = null;
                session.playerSummary = null;
                delete session.isPlayerLocked;

                yield put(SET_SESSION(session));
                yield call(saveSession);
            }
        } else {
            // Clear the currently selected player id, player summary and player lock state.
            session.playerId = null;
            session.playerSummary = null;
            delete session.isPlayerLocked;

            yield put(SET_SESSION(session));
            yield call(saveSession);
        }

        return true;
    } catch (error: any) {
        console.error("setPlayerId - ERROR", error);
    } finally {
        // If the optional path and history are both present, push the requested path onto the history.
        if (playerFound && action.payload.history && action.payload.path) {
            if (action.payload.history.location.pathname !== action.payload.path) {
                window.setTimeout(() => {
                    if (action.payload.history && action.payload.path) {
                        action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
                    }
                }, 0);
            } else {
                window.setTimeout(() => {
                    if (action.payload.history && action.payload.path) {
                        action.payload.history.replace(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
                    }
                }, 0);
            }
        }
    }

    return false;
}

// Attempts to check the session's "Live Lock" state for the currently selected app.
export function* checkLiveLock(action: CheckLiveLock) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    let session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    try {
        console.log("Checking Live Lock", targetApp.appId, targetApp.appName);

        session = yield select(getSession);

        let disableLiveLock = session.disableLiveLock;
        let isLiveLocked = false;

        if (targetApp.isLive) {
            if (action.payload.disableLiveLock) {
                const response: HttpResponse = yield Http.GET("admin/development/game-read-settings", undefined, Http.JSON_HEADERS);

                if (Http.isStatusOk(response)) {
                    if (response.data.liveLockDisabled) {
                        console.log("Live Lock is DISABLED");

                        disableLiveLock = true;
                        isLiveLocked = false;
                    } else {
                        console.log("Live Lock is ENABLED");

                        disableLiveLock = false;
                        isLiveLocked = true;
                    }
                } else {
                    disableLiveLock = false;
                    isLiveLocked = true;
                }
            }

            if (action.payload.privilegeId) {
                const response: HttpResponse = yield Http.GET("admin/development/is-application-privileges-protected", { privilegeId: action.payload.privilegeId }, Http.JSON_HEADERS);

                if (Http.isStatusOk(response)) {
                    if (response.data.isApplicationAccessProtected && !disableLiveLock) {
                        console.log("App is PROTECTED");

                        isLiveLocked = true;
                    } else {
                        console.log("App is UNPROTECTED");

                        isLiveLocked = false;
                    }
                }
            }
        } else {
            disableLiveLock = false;
            isLiveLocked = false;
        }

        let latestSession: Session = yield select(getSession);

        latestSession.disableLiveLock = disableLiveLock;
        latestSession.isLiveLocked = isLiveLocked;

        yield put(SET_SESSION(CloneUtils.clone(latestSession)));
        yield call(saveSession);

        return true;
    } catch (error: any) {
        console.error("checkLiveLock - ERROR", error);
    }

    return false;
}

// Attempts to toggle the session's "Live Lock" state.
export function* toggleLiveLock(action: ToggleLiveLock) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    try {
        console.log("Toggling Live Lock", targetApp.appId, targetApp.appName);

        if (targetApp.isLive) {
            if (action.payload.isLiveLocked != null) {
                // If the "isLiveLock" parameter was passed then force the live lock state.
                if (action.payload.isLiveLocked) {
                    // Attempt to "protect" (i.e. disable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/protect-application-privileges", undefined, undefined, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = true;
                    }
                } else {
                    // Attempt to "unprotect" (i.e. enable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/unprotect-app-privileges", undefined, { appName: action.payload.appName }, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = false;
                    }
                }
            } else {
                // Otherwise we just toggle the live lock state based on the current state of the session.
                if (session.isLiveLocked) {
                    // Attempt to "unprotect" (i.e. enable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/unprotect-app-privileges", undefined, { appName: action.payload.appName }, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = false;
                    }
                } else {
                    // Attempt to "protect" (i.e. disable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/protect-application-privileges", undefined, undefined, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = true;
                    }
                }
            }
        } else {
            session.isLiveLocked = false;
        }

        yield put(SET_SESSION(session));
        yield call(saveSession);

        // Load the available privileges and screen settings.
        yield all([call(populateAvailablePrivileges), call(populateScreenSettings)]);

        return true;
    } catch (error: any) {
        console.error("toggleLiveLock - ERROR", error);
    }

    return false;
}

// Attempts to check the session's "Player Lock" state for the currently selected player.
export function* checkPlayerLock(_action: CheckPlayerLock) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    let session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    // If there is no selected playerId, then just return (nothing to do);
    if (!session.playerId) return false;

    try {
        console.log("Checking Player Lock", session.playerId);

        session = yield select(getSession);

        let isPlayerLocked: boolean | null = null;

        if (targetApp.isLive) {
            isPlayerLocked = true;

            const response: HttpResponse = yield Http.GET("admin/development/is-end-user-unlocked", { endUserProfileId: session.playerId }, Http.JSON_HEADERS);

            if (Http.isStatusOk(response)) {
                if (response.data === false) {
                    console.log("Player is PROTECTED");

                    isPlayerLocked = true;
                } else {
                    console.log("Player is UNPROTECTED");

                    isPlayerLocked = false;
                }
            }
        }

        let latestSession: Session = yield select(getSession);

        if (isPlayerLocked != null) {
            latestSession.isPlayerLocked = isPlayerLocked;
        } else {
            delete latestSession.isPlayerLocked;
        }

        yield put(SET_SESSION(CloneUtils.clone(latestSession)));
        yield call(saveSession);

        return true;
    } catch (error: any) {
        console.error("checkPlayerLock - ERROR", error);
    }

    return false;
}

// Attempts to toggle the session's "Player Lock" state.
export function* togglePlayerLock(action: TogglePlayerLock) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    // If there is no selected playerId, then just return (nothing to do);
    if (!session.playerId) return false;

    try {
        console.log("Toggling Player Lock", session.playerId);

        if (targetApp.isLive) {
            // Otherwise we just toggle the player lock state based on the current state of the session.
            if (session.isPlayerLocked) {
                // Attempt to "unprotect" (i.e. enable for editing) the player.
                const response: HttpResponse = yield Http.POST("admin/development/unlock-end-user", { endUserProfileId: session.playerId }, undefined, Http.JSON_HEADERS);
                if (Http.isStatusOk(response)) {
                    session.isPlayerLocked = false;
                }
            } else {
                // Attempt to "protect" (i.e. disable for editing) the player.
                const response: HttpResponse = yield Http.POST("admin/development/lock-end-user", { endUserProfileId: session.playerId }, undefined, Http.JSON_HEADERS);
                if (Http.isStatusOk(response)) {
                    session.isPlayerLocked = true;
                }
            }
        } else {
            session.isPlayerLocked = false;
        }

        yield put(SET_SESSION(session));
        yield call(saveSession);

        return true;
    } catch (error: any) {
        console.error("togglePlayerLock - ERROR", error);
    }

    return false;
}

export function* dismissSystemMessage(_action?: DismissSystemMessage) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    applicationInformation.systemMessage.isDismissed = true;
    yield put(SET_APPLICATION_INFORMATION(applicationInformation));
}

export const appGeneratorMap = {
    "app.setAppId": setAppId,
    "app.setPlayerId": setPlayerId,
    "app.populateAvailableApps": populateAvailableApps,
    "app.checkLiveLock": checkLiveLock,
    "app.toggleLiveLock": toggleLiveLock,
    "app.checkPlayerLock": checkPlayerLock,
    "app.togglePlayerLock": togglePlayerLock,
    "app.dismissSystemMessage": dismissSystemMessage,
};

export default function* root() {
    yield all([
        takeLatest("app.populateAvailableApps", populateAvailableApps),
        takeLatest("app.setAppId", setAppId),
        takeLatest("app.setPlayerId", setPlayerId),
        takeLatest("app.checkLiveLock", checkLiveLock),
        takeLatest("app.toggleLiveLock", toggleLiveLock),
        takeLatest("app.checkPlayerLock", checkPlayerLock),
        takeLatest("app.togglePlayerLock", togglePlayerLock),
        takeLatest("app.dismissSystemMessage", dismissSystemMessage),
    ]);
}
