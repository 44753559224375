import React, { CSSProperties } from "react";
import { toast as nativeToast, ToastContent } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import Button from "components/common/button/Button";
import ClipboardUtils from "utils/Clipboard";

export interface ToastContext {
    email: string | null;

    isSuper?: boolean;

    teamId: string | null;
    accountNumber: string | null;

    appId: string | null;
    appName: string | null;

    profileId: string | null;

    page_path: string | null;
    page_search: string | null;
    page_hash: string | null;
    page_location: string | null;
}

const toastContext: ToastContext = {
    email: null,

    teamId: null,
    accountNumber: null,

    appId: null,
    appName: null,

    profileId: null,

    page_path: null,
    page_search: null,
    page_hash: null,
    page_location: null,
};

export const updateToastContext = (context: ToastContext) => {
    Object.assign(toastContext, context);
};

const isError = (val: any) => {
    return val instanceof Error;
};

const isObject = (val: any) => {
    return val != null && typeof val === "object" && !Array.isArray(val) && !isError(val);
};

const isErrorObject = (val: any) => {
    return isObject(val) && ((val as any).errorCode != null || (val as any).errorMessage != null || (val as any).code != null || (val as any).message != null);
};

const prepareForCopy = (val: any) => {
    if (val == null) return null;
    if (isError(val)) return (val as Error).stack || (val as Error).toString();
    if (isErrorObject(val) || isObject(val)) return JSON.stringify(val, undefined, 2);

    return "" + val;
};

const prepareForRender = (content: any, copyToClipboardValue?: any) => {
    const isContentRenderable = !isError(content) && !isErrorObject(content);

    let renderedContent: any = null;
    let copyValue: any = null;

    if (isContentRenderable) {
        if (isErrorObject(copyToClipboardValue)) {
            renderedContent = (
                <>
                    {content}

                    <Typography>{`[${copyToClipboardValue.errorCode != null ? copyToClipboardValue.errorCode : copyToClipboardValue.code}] - ${
                        copyToClipboardValue.errorMessage != null ? copyToClipboardValue.errorMessage : copyToClipboardValue.message
                    }`}</Typography>
                </>
            );
        } else {
            renderedContent = content;
        }

        if (copyToClipboardValue != null) {
            copyValue = "---- CONTEXT ----\n\n" + JSON.stringify(toastContext, undefined, 2) + "\n\n";
            copyValue += "---- DETAILS ----\n\n" + prepareForCopy(copyToClipboardValue) + "\n\n";
        }
    } else {
        if (isError(content)) {
            renderedContent = <Typography>{(content as Error).toString()}</Typography>;
        } else {
            if (isErrorObject(content)) {
                renderedContent = <Typography>{`[${content.errorCode != null ? content.errorCode : content.code}] - ${content.errorMessage != null ? content.errorMessage : content.message}`}</Typography>;
            } else {
                renderedContent = <Typography>{content.toString()}</Typography>;
            }
        }

        copyValue = "---- CONTEXT ----\n\n" + JSON.stringify(toastContext, undefined, 2) + "\n\n";
        copyValue += "---- DETAILS ----\n\n" + prepareForCopy(content) + "\n\n";

        if (copyToClipboardValue) {
            copyValue += "---- ADDITIONAL DETAILS ----\n\n" + prepareForCopy(copyToClipboardValue) + "\n\n";
        }
    }

    return (
        <span style={{ display: "flex", flexDirection: "column" }}>
            {renderedContent}

            {copyValue && (
                <Button
                    style={{ color: "rgb(255, 255, 255)", backgroundColor: "rgba(0, 0, 0, 0.5)", borderColor: "rgba(0, 0, 0, 0.5)", maxHeight: "1em", fontSize: "0.75em", marginTop: "0.625em", marginBottom: "-6px" }}
                    onClick={() => ClipboardUtils.writeText(copyValue, true)}
                    onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}
                >
                    <Trans>Copy To Clipboard</Trans>
                </Button>
            )}
        </span>
    );
};

const style: CSSProperties = {
    display: "flex",
    flexDirection: "column",
};

export const toast = {
    info: (content: ToastContent, copyValue?: any) => {
        nativeToast(<span style={style}>{prepareForRender(content, copyValue)}</span>);
    },
    success: (content: ToastContent, copyValue?: any) => {
        nativeToast.success(<span style={style}>{prepareForRender(content, copyValue)}</span>);
    },
    error: (content: ToastContent, copyValue?: any) => {
        nativeToast.error(<span style={style}>{prepareForRender(content, copyValue)}</span>);
    },
    warning: (content: ToastContent, copyValue?: any) => {
        nativeToast.warning(<span style={style}>{prepareForRender(content, copyValue)}</span>);
    },
};

export default toast;
