// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { Trans } from "@lingui/macro";

// Import types.
import { CookieConsentLevel } from "utils/CookieConsent";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import { PortalDisabled, SessionIdle, SessionSynchronization } from "./types";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import components.
import { Typography } from "@mui/material";
import Dashboard from "components/Dashboard";
import Button from "components/common/button/Button";
import TermsOfService from "components/common/dialog/TermsOfService";
import CustomDialog from "components/common/dialog/CustomDialog";
import LoadingProgress from "components/common/widgets/LoadingProgress";

// Import utilities.
import Http from "utils/networking/Http";
import LocalStorageUtils from "utils/LocalStorage";
import CloneUtils from "utils/Clone";
import PollerUtils, { PollerStatus } from "utils/Poller";
import { SESSION_IDLE_POLLER_ID, SERVER_SESSION_CONTEXT_POLLER_ID } from "utils/PollerIds";
import { ToastContext, updateToastContext } from "utils/Toast";

// Define the various properties accepted by this component.
interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    logout: () => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

// Define the local state managed by this component.
interface STATE {
    sessionSynchronization: SessionSynchronization;
    sessionIdle: SessionIdle;
    portalDisabled: PortalDisabled;
}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        applicationInformation: state.applicationInformation,
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        logout: () => dispatch({ type: "authentication.logout" }),
    };
};

const POLLER_INTERVAL = 5000;

// Implementation of the Session Idle poller.
const sessionIdlePoller = async (session: Session, sessionIdle: SessionIdle, updateSessionIdle: (sessionIdle: SessionIdle) => void) => {
    if (!session.sessionLength || !session.sessionTimeout) return;

    // Get the current session idle data.
    const lastActivity = sessionIdle.lastActivity;
    const lastTimeout = sessionIdle.lastTimeout;
    const forceLogout = sessionIdle.forceLogout;

    // Ensure our poller is still valid and running (in case it was PAUSED or STOPPED during execution).
    // This is IMPORTANT in order to prevent any undesirable side-effects.
    const currentPoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
    if (!currentPoller || currentPoller.status === PollerStatus.PAUSED) {
        return;
    }

    // Get the current date/time.
    const now = new Date();

    if (lastTimeout == null) {
        if (now.getTime() - lastActivity.getTime() > session.sessionLength * 1000) {
            updateSessionIdle({
                inProgress: true,
                forceLogout: false,
                lastActivity: lastActivity,
                lastTimeout: now,
            });
        }
    } else {
        if (!forceLogout && now.getTime() - lastTimeout.getTime() > session.sessionTimeout * 1000) {
            updateSessionIdle({
                inProgress: true,
                forceLogout: true,
                lastActivity: lastActivity,
                lastTimeout: lastTimeout,
            });
        }
    }
};

// Implementation of the Server Session Context poller.
const serverSessionContextPoller = async (session: Session, sessionSynchronization: SessionSynchronization, updateSessionSynchronization: (sessionSynchronization: SessionSynchronization) => void) => {
    const MAX_CONSECUTIVE_FAILURE_COUNT = 2;

    if (!session.isAuthenticated || !session.companyId) return;

    // Ensure our poller is still valid and running (in case it was PAUSED or STOPPED during execution).
    // This is IMPORTANT in order to prevent any undesirable side-effects.
    const currentPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
    if (!currentPoller || currentPoller.status === PollerStatus.PAUSED) {
        return;
    }

    // Perform the call to get the current server session context.
    const response = await Http.GET("admin/get-server-session-context");

    // If the poller was paused and/or stopped while we waited for the response from the server
    // we need to ignore the response.
    if (currentPoller?.status !== PollerStatus.RUNNING) {
        return;
    }

    if (Http.isStatusOk(response)) {
        if (Http.isRedirect(response)) {
            // A redirect response for the server session context means we are no longer logged in.
            console.log("Server Session Context Redirect");

            updateSessionSynchronization({
                inProgress: true,
                forceLogout: true,
                companyId: null,
                companyIdAlias: null,
                appId: null,
                consecutiveFailureCount: 0,
            });

            return;
        }

        // Get the response data.
        const data = response.data;

        // If we have no data, then there is nothing to do, so just return.
        if (!data) return;

        // Track whether we need to update the current session.
        let requiresUpdate = false;

        // Check if the selected companyId has changed.
        if ((session.companyId != null || data.companyId != null) && session.companyId !== data.companyId) {
            console.log("Selected Company Id was changed from another session", session.companyId, data.companyId);
            requiresUpdate = true;
        }

        // Check if the selected companyIdAlias has changed.
        if (session.isSuper) {
            // We only care about the companyIdAlias if the user is logged in as SUPER.
            if ((session.companyIdAlias != null || data.teamIdForTeamContext != null) && session.companyIdAlias !== data.teamIdForTeamContext) {
                console.log("Selected Company Id Alias was changed from another session", session.companyIdAlias, data.teamIdForTeamContext);
                requiresUpdate = true;
            }
        }

        // Check if the selected appId has changed.
        if ((session.appId != null || data.gameId != null) && session.appId !== data.gameId) {
            console.log("Selected App Id was changed from another session", session.appId, data.gameId);
            requiresUpdate = true;
        }

        // If the session requires an update, then trigger the Session Synchronization Dialog.
        if (requiresUpdate) {
            console.log("Triggering Session Syncrhonization With Server", session, data);

            updateSessionSynchronization({
                inProgress: true,
                forceLogout: false,
                companyId: data.companyId,
                companyIdAlias: data.teamIdForTeamContext,
                appId: data.gameId,
                consecutiveFailureCount: 0,
            });
        } else {
            // Otherwise the session synchronization is good, simply reset the failure count to 0 (if necessary).
            if (sessionSynchronization.consecutiveFailureCount > 0) {
                updateSessionSynchronization({
                    inProgress: sessionSynchronization.inProgress,
                    forceLogout: sessionSynchronization.forceLogout,
                    companyId: sessionSynchronization.companyId,
                    companyIdAlias: sessionSynchronization.companyIdAlias,
                    appId: sessionSynchronization.appId,
                    consecutiveFailureCount: 0,
                });
            }
        }
    } else {
        console.log("Server Session Context Failure", response);

        // The call to get the current server session context failed (this could be the result of our session being invalidated, so force a logout).
        // To deal with intermitent gateway timeouts we require a certain number of consecutive failures before we force a logout.
        if (sessionSynchronization.consecutiveFailureCount >= MAX_CONSECUTIVE_FAILURE_COUNT) {
            updateSessionSynchronization({
                inProgress: true,
                forceLogout: true,
                companyId: null,
                companyIdAlias: null,
                appId: null,
                consecutiveFailureCount: 0,
            });
        } else {
            updateSessionSynchronization({
                inProgress: sessionSynchronization.inProgress,
                forceLogout: sessionSynchronization.forceLogout,
                companyId: sessionSynchronization.companyId,
                companyIdAlias: sessionSynchronization.companyIdAlias,
                appId: sessionSynchronization.appId,
                consecutiveFailureCount: sessionSynchronization.consecutiveFailureCount + 1,
            });
        }
    }
};

class PortalAuthenticated extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        sessionSynchronization: {
            inProgress: false,
            forceLogout: false,
            companyId: null,
            companyIdAlias: null,
            appId: null,
            consecutiveFailureCount: 0,
        },
        sessionIdle: {
            inProgress: false,
            forceLogout: false,
            lastActivity: new Date(),
            lastTimeout: null,
        },
        portalDisabled: {
            inProgress: false,
            timeRemaining: null,
        },
    };
    private autoDismissTimeout = 0;

    componentDidMount() {
        const { applicationInformation } = this.props;

        if (!applicationInformation.loadingBasicState) {
            // Start the Session Idle poller (if necessary).
            // This checks if the user has been idle for too long.
            const currentSessionIdlePoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
            if (!currentSessionIdlePoller) {
                PollerUtils.startPoller(
                    SESSION_IDLE_POLLER_ID, // Poller ID.
                    0, // Start delay in ms (0 = immediately).
                    0, // Maximum number of iterations (0 = infinite).
                    POLLER_INTERVAL, // Interval between iterations in ms.
                    sessionIdlePoller, // The actual poller implementation function.
                    [this.props.session, this.state.sessionIdle, (sessionIdle: SessionIdle) => this.setState({ sessionIdle })]
                );
            } else {
                if (currentSessionIdlePoller.status === PollerStatus.PAUSED) {
                    PollerUtils.resumePoller(SESSION_IDLE_POLLER_ID);
                }
            }

            // Start or resume the Server Session Context poller (if necessary).
            // This keeps our server session alive as long as we are logged in.
            const currentServerSessionContextPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
            if (!currentServerSessionContextPoller) {
                PollerUtils.startPoller(
                    SERVER_SESSION_CONTEXT_POLLER_ID, // Poller ID.
                    0, // Start delay in ms (0 = immediately).
                    0, // Maximum number of iterations (0 = infinite).
                    POLLER_INTERVAL, // Interval between iterations in ms.
                    serverSessionContextPoller, // The actual poller implementation function.
                    [this.props.session, this.state.sessionSynchronization, (sessionSynchronization: SessionSynchronization) => this.setState({ sessionSynchronization })]
                );
            } else {
                if (currentServerSessionContextPoller.status === PollerStatus.PAUSED) {
                    PollerUtils.resumePoller(SERVER_SESSION_CONTEXT_POLLER_ID);
                }
            }
        }
    }

    componentDidUpdate(prevProps: PROPS, prevState: STATE) {
        // If we were not loading basic state but now we are, then control the pollers as necessary.
        if (!prevProps.applicationInformation.loadingBasicState && this.props.applicationInformation.loadingBasicState) {
            // Pause the Session Idle poller (if necessary).
            // This checks if the user has been idle for too long.
            const currentSessionIdlePoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
            if (currentSessionIdlePoller) {
                if (currentSessionIdlePoller.status !== PollerStatus.PAUSED) {
                    PollerUtils.pausePoller(SESSION_IDLE_POLLER_ID);
                }
            }

            // Pause the Server Session Context poller (if necessary).
            // This keeps our server session alive as long as we are logged in.
            const currentServerSessionContextPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
            if (currentServerSessionContextPoller) {
                if (currentServerSessionContextPoller.status !== PollerStatus.PAUSED) {
                    PollerUtils.pausePoller(SERVER_SESSION_CONTEXT_POLLER_ID);
                }
            }
        }

        // If we were loading basic state but now we are not, then control the pollers as necessary.
        if (prevProps.applicationInformation.loadingBasicState && !this.props.applicationInformation.loadingBasicState) {
            // Resume or start the Session Idle poller (if necessary).
            const currentSessionIdlePoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
            if (currentSessionIdlePoller) {
                if (currentSessionIdlePoller.status === PollerStatus.PAUSED) {
                    PollerUtils.resumePoller(SESSION_IDLE_POLLER_ID);
                }
            } else {
                PollerUtils.startPoller(
                    SESSION_IDLE_POLLER_ID, // Poller ID.
                    0, // Start delay in ms (0 = immediately).
                    0, // Maximum number of iterations (0 = infinite).
                    POLLER_INTERVAL, // Interval between iterations in ms.
                    sessionIdlePoller, // The actual poller implementation function.
                    [this.props.session, this.state.sessionIdle, (sessionIdle: SessionIdle) => this.setState({ sessionIdle })]
                );
            }

            // Resume or start the Server Session Context poller (if necessary).
            const currentServerSessionContextPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
            if (currentServerSessionContextPoller) {
                if (currentServerSessionContextPoller.status === PollerStatus.PAUSED) {
                    PollerUtils.resumePoller(SERVER_SESSION_CONTEXT_POLLER_ID);
                }
            } else {
                PollerUtils.startPoller(
                    SERVER_SESSION_CONTEXT_POLLER_ID, // Poller ID.
                    0, // Start delay in ms (0 = immediately).
                    0, // Maximum number of iterations (0 = infinite).
                    POLLER_INTERVAL, // Interval between iterations in ms.
                    serverSessionContextPoller, // The actual poller implementation function.
                    [this.props.session, this.state.sessionSynchronization, (sessionSynchronization: SessionSynchronization) => this.setState({ sessionSynchronization })]
                );
            }
        }

        // If the sessionIdle state has been updated then we need to update the Session Idle poller options.
        if (prevState.sessionIdle !== this.state.sessionIdle) {
            // Update the Session Idle poller (if necessary).
            const currentSessionIdlePoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
            if (currentSessionIdlePoller) {
                // If we are forcing a logout (due to inactivity), then stop both the Session Idle and Server Session Context pollers.
                // Since we will force a logout, there is no point in allowing them to continue running.
                if (this.state.sessionIdle.forceLogout) {
                    PollerUtils.stopPoller(SESSION_IDLE_POLLER_ID);
                    PollerUtils.stopPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
                } else {
                    // Otherwise we simply update the Session Idle poller options.
                    PollerUtils.updatePoller(
                        SESSION_IDLE_POLLER_ID, // Poller ID.
                        0, // Maximum number of iterations (0 = infinite).
                        POLLER_INTERVAL, // Interval between iterations in ms.
                        [this.props.session, this.state.sessionIdle, (sessionIdle: SessionIdle) => this.setState({ sessionIdle })]
                    );
                }
            }
        }

        // If the session prop has been updated then we need to update the Server Session Context poller options.
        if (prevProps.session !== this.props.session || prevState.sessionSynchronization !== this.state.sessionSynchronization) {
            // Update the Server Session Context poller (if necessary).
            const currentServerSessionContextPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
            if (currentServerSessionContextPoller) {
                // If we are forcing a logout (due to session synchronization failure or session expiry), then stop both the Session Idle and Server Session Context pollers.
                // Since we will force a logout, there is no point in allowing them to continue running.
                if (this.state.sessionSynchronization.forceLogout) {
                    PollerUtils.stopPoller(SESSION_IDLE_POLLER_ID);
                    PollerUtils.stopPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
                } else {
                    // Otherwise we simply update the Server Session Context poller options.
                    PollerUtils.updatePoller(
                        SERVER_SESSION_CONTEXT_POLLER_ID, // Poller ID.
                        0, // Maximum number of iterations (0 = infinite).
                        POLLER_INTERVAL, // Interval between iterations in ms.
                        [this.props.session, this.state.sessionSynchronization, (sessionSynchronization: SessionSynchronization) => this.setState({ sessionSynchronization })]
                    );
                }

                if (!prevState.sessionSynchronization.inProgress && this.state.sessionSynchronization.inProgress) {
                    this.triggerAutoDismissOfSessionSynchronizationDialog();
                }
            }
        }

        // If the disabled state has been updated then we need to update the Portal Disabled poller options.
        if (this.props.applicationInformation.disabled && !this.props.session.isSuper && !this.state.portalDisabled.inProgress) {
            this.setState(
                {
                    portalDisabled: {
                        inProgress: true,
                        timeRemaining: 5,
                    },
                },
                () => {
                    this.triggerAutoDismissOfPortalDisabledDialog();
                }
            );
        }

        if (prevProps.session !== this.props.session) {
            const currentComapny = this.props.availableCompanies.find((item) => item.companyId === (this.props.session.isSuper ? this.props.session.companyIdAlias : this.props.session.companyId)) || null;
            const currentApp = this.props.availableApps.find((item) => item.appId === this.props.session.appId) || null;

            updateToastContext({
                email: this.props.session.email || null,

                isSuper: this.props.session.isSuper ? true : undefined,

                teamId: currentComapny?.companyId || null,
                accountNumber: currentComapny?.accountNumber || null,

                appId: currentApp?.appId || null,
                appName: currentApp?.appName || null,

                profileId: this.props.session.playerId || null,
            } as ToastContext);
        }
    }

    componentWillUnmount() {
        // When unmounting, stop the Session Idle poller if it hasn't already been stopped.
        const currentSessionIdlePoller = PollerUtils.getPoller(SESSION_IDLE_POLLER_ID);
        if (currentSessionIdlePoller) {
            PollerUtils.stopPoller(SESSION_IDLE_POLLER_ID);
        }

        // When unmounting, stop the Server Session Context poller if it hasn't already been stopped.
        const currentServerSessionContextPoller = PollerUtils.getPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
        if (currentServerSessionContextPoller) {
            PollerUtils.stopPoller(SERVER_SESSION_CONTEXT_POLLER_ID);
        }

        updateToastContext({
            email: null,

            isSuper: undefined,

            teamId: null,
            accountNumber: null,

            appId: null,
            appName: null,

            profileId: null,

            page_path: null,
            page_search: null,
            page_hash: null,
            page_location: null,
        } as ToastContext);
    }

    updateLastActivity = () => {
        const { sessionIdle } = this.state;

        const updatedSessionIdle = CloneUtils.clone(sessionIdle) as SessionIdle;

        updatedSessionIdle.lastActivity = new Date();
        updatedSessionIdle.lastTimeout = null;

        this.setState({ sessionIdle: updatedSessionIdle });
    };

    dismissSessionIdleDialog = () => {
        const { sessionIdle } = this.state;

        if (!sessionIdle.inProgress) return;

        if (sessionIdle.forceLogout) {
            this.props.logout();
        } else {
            this.setState({
                sessionIdle: {
                    inProgress: false,
                    forceLogout: false,
                    lastActivity: new Date(),
                    lastTimeout: null,
                },
            });
        }
    };

    dismissSessionSynchronizationDialog = () => {
        const { session, currentUser, availableCompanies } = this.props;
        const { sessionSynchronization } = this.state;

        if (!sessionSynchronization.inProgress) return;

        if (this.autoDismissTimeout > 0) {
            window.clearTimeout(this.autoDismissTimeout);
        }

        if (sessionSynchronization.forceLogout) {
            this.props.logout();
        } else {
            const updatedSession = CloneUtils.clone(session) as Session;

            updatedSession.companyId = sessionSynchronization.companyId;
            updatedSession.companyIdAlias = sessionSynchronization.companyIdAlias;
            updatedSession.appId = sessionSynchronization.appId;

            // Find the currently selected company.
            const teamInfo = availableCompanies.find((item) => item.companyId === updatedSession.companyId) || null;

            // Update the isSuper and isTeamAdmin flags of the session based on the currently selected company.
            if (teamInfo) {
                updatedSession.isSuper = teamInfo.isSuper;
                updatedSession.isTeamAdmin = teamInfo.isAdmin;
            } else {
                updatedSession.isSuper = false;
                updatedSession.isTeamAdmin = false;
            }

            // Update the various items persisted in local storage.
            if (currentUser) {
                if (updatedSession.companyId) {
                    if (!updatedSession.isSuper) {
                        // If the newly selected company is a Non-SUPER team, then update the "lastteam_autoselect_<profileId>" in local storage.
                        // This is used to auto-select the Non-SUPER company dropdown list when logging in.
                        LocalStorageUtils.setItem(
                            "lastteam_autoselect_" + currentUser.profileId,
                            JSON.stringify({
                                companyId: updatedSession.companyId,
                            }),
                            CookieConsentLevel.FUNCTIONALITY
                        );
                    }

                    // Update the "lastteam_<profileId>" in local storage.
                    // This represents that last company the user was logged in with (either a SUPER team or a Non-SUPER team).
                    LocalStorageUtils.setItem(
                        "lastteam_" + currentUser.profileId,
                        JSON.stringify({
                            companyId: updatedSession.companyId,
                        }),
                        CookieConsentLevel.FUNCTIONALITY
                    );
                }

                if (updatedSession.companyIdAlias) {
                    // Update the "lastalias_<profileId>" in local storage.
                    LocalStorageUtils.setItem(
                        "lastalias_" + currentUser.profileId,
                        JSON.stringify({
                            companyId: updatedSession.companyIdAlias,
                        }),
                        CookieConsentLevel.FUNCTIONALITY
                    );
                }

                if (updatedSession.appId) {
                    if (updatedSession.isSuper) {
                        // Update the "lastgame_<profileId>_<companyIdAlias>" in local storage.
                        LocalStorageUtils.setItem(
                            "lastgame_" + currentUser.profileId + "_" + updatedSession.companyIdAlias,
                            JSON.stringify({
                                gameId: updatedSession.appId,
                            }),
                            CookieConsentLevel.FUNCTIONALITY
                        );
                    } else {
                        // Update the "lastgame_<profileId>_<companyId>" in local storage.
                        LocalStorageUtils.setItem(
                            "lastgame_" + currentUser.profileId + "_" + updatedSession.companyId,
                            JSON.stringify({
                                gameId: updatedSession.appId,
                            }),
                            CookieConsentLevel.FUNCTIONALITY
                        );
                    }
                }
            }

            // Save the updated session to local storage.
            LocalStorageUtils.setItem(LocalStorageUtils.PORTAL_SESSION, JSON.stringify(updatedSession), CookieConsentLevel.FUNCTIONALITY);

            // Trigger a reload of the page.
            console.log("### Reloading Due To Session Synchronization");

            window.location.reload();
        }
    };

    dismissPortalDisabledDialog = () => {
        const { portalDisabled } = this.state;

        if (!portalDisabled.inProgress) return;

        if (this.autoDismissTimeout > 0) {
            window.clearTimeout(this.autoDismissTimeout);
        }

        this.props.logout();
    };

    triggerAutoDismissOfSessionSynchronizationDialog = () => {
        const { session } = this.props;

        if (this.autoDismissTimeout > 0) {
            window.clearTimeout(this.autoDismissTimeout);
        }

        this.autoDismissTimeout = window.setTimeout(
            () => {
                this.dismissSessionSynchronizationDialog();
            },
            session.sessionSynchronizationTimeout != null && session.sessionSynchronizationTimeout > 0 ? session.sessionSynchronizationTimeout * 1000 : 0
        );
    };

    triggerAutoDismissOfPortalDisabledDialog = () => {
        if (this.autoDismissTimeout > 0) {
            window.clearTimeout(this.autoDismissTimeout);
        }

        this.autoDismissTimeout = window.setTimeout(() => {
            if (this.state.portalDisabled.timeRemaining != null) {
                if (this.state.portalDisabled.timeRemaining > 0) {
                    this.setState(
                        {
                            portalDisabled: {
                                inProgress: true,
                                timeRemaining: this.state.portalDisabled.timeRemaining - 1,
                            },
                        },
                        this.triggerAutoDismissOfPortalDisabledDialog
                    );
                } else {
                    this.dismissPortalDisabledDialog();
                }
            }
        }, 1000);
    };

    render() {
        const { classes, applicationInformation, session } = this.props;
        const { sessionIdle, sessionSynchronization, portalDisabled } = this.state;

        return (
            <div
                id="authenticated"
                className={classes.root}
                onMouseDown={this.updateLastActivity}
                onMouseUp={this.updateLastActivity}
                onScroll={this.updateLastActivity}
                onTouchStart={this.updateLastActivity}
                onTouchEnd={this.updateLastActivity}
                onKeyDown={this.updateLastActivity}
                onKeyUp={this.updateLastActivity}
            >
                <div className={classes.content} style={{ alignItems: "stretch", justifyContent: "stretch" }}>
                    <Dashboard />

                    <TermsOfService />

                    {(applicationInformation.loadingLoginState || applicationInformation.loadingBasicState) && (
                        <div className={"centered"} style={{ margin: 0 }}>
                            <LoadingProgress label={<Trans>Please Wait...</Trans>} />
                        </div>
                    )}
                </div>

                <CustomDialog
                    id="session-idle"
                    className={classes.dialog}
                    open={sessionIdle.inProgress}
                    ready={true}
                    header={!sessionIdle.forceLogout ? <Trans>Session Idle</Trans> : <Trans>Session Timeout</Trans>}
                    hideDismiss={true}
                    hideFullscreen={true}
                    content={
                        <>
                            <Typography>
                                {!sessionIdle.forceLogout && <Trans>Your session has been idle for too long.</Trans>}
                                {sessionIdle.forceLogout && <Trans>Your session has timed out.</Trans>}
                            </Typography>
                        </>
                    }
                    actions={
                        <>
                            {!sessionIdle.forceLogout && (
                                <Button id={"continue"} type={"primary"} style={{ marginLeft: "auto" }} onClick={this.dismissSessionIdleDialog}>
                                    <Trans>Continue</Trans>
                                </Button>
                            )}
                            {sessionIdle.forceLogout && (
                                <Button id={"login"} type={"primary"} style={{ marginLeft: "auto" }} onClick={this.dismissSessionIdleDialog}>
                                    <Trans>Login</Trans>
                                </Button>
                            )}
                        </>
                    }
                />

                <CustomDialog
                    id="session-synchronization"
                    className={classes.dialog}
                    open={sessionSynchronization.inProgress && session.sessionSynchronizationTimeout != null && session.sessionSynchronizationTimeout > 0}
                    ready={true}
                    header={!sessionSynchronization.forceLogout ? <Trans>Server Session Modified</Trans> : <Trans>Server Session Disconnected</Trans>}
                    hideDismiss={true}
                    hideFullscreen={true}
                    content={
                        <>
                            <Typography>
                                {!sessionSynchronization.forceLogout && <Trans>Your session has been modified by another browser or external process.</Trans>}
                                {sessionSynchronization.forceLogout && <Trans>Your session has been disconnected.</Trans>}
                            </Typography>
                        </>
                    }
                    actions={
                        <>
                            <Button id={"continue"} type={"primary"} style={{ marginLeft: "auto" }} onClick={this.dismissSessionSynchronizationDialog}>
                                <Trans>Continue</Trans>
                            </Button>
                        </>
                    }
                />

                <CustomDialog
                    id="portal-disabled"
                    className={classes.dialog}
                    open={portalDisabled.inProgress}
                    ready={true}
                    header={<Trans>Portal Disabled</Trans>}
                    hideDismiss={true}
                    hideFullscreen={true}
                    content={
                        <>
                            <Typography>
                                <Trans>The portal has been temporarily disabled by the system administrator.</Trans>
                            </Typography>

                            <Typography>
                                <Trans>You will automatically be logged out in {portalDisabled.timeRemaining} seconds.</Trans>
                            </Typography>
                        </>
                    }
                    actions={
                        <>
                            <Button id={"logout"} type={"primary"} style={{ marginLeft: "auto" }} onClick={this.dismissPortalDisabledDialog}>
                                <Trans>Logout</Trans>
                            </Button>
                        </>
                    }
                />
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        content: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        dialog: {
            "& .MuiDialog-paper": {
                flex: "0 0 auto",
            },
            "& .MuiDialogContent-root": {
                justifyContent: "center",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(PortalAuthenticated)));
